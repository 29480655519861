import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppRouting } from "./app.routing";
import ComponentLoader from "./components/component-loader/component-loader";
import { LanguagesEnum } from "./core/enums/languages.enum";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const storgeLang = localStorage.getItem("language");

    if (storgeLang) {
      if (storgeLang === LanguagesEnum.EN) {
        i18n.changeLanguage(LanguagesEnum.EN);
        document.documentElement.style.setProperty(
          "--font-Regular",
          "IBMPlexSans-Regular"
        );
        document.documentElement.style.setProperty(
          "--font-Bold",
          "IBMPlexSans-Bold"
        );
        document.documentElement.style.setProperty(
          "--font-Light",
          "IBMPlexSans-Light"
        );
      } else {
        i18n.changeLanguage(LanguagesEnum.AR);
        document.documentElement.style.setProperty(
          "--font-Regular",
          "Cairo-Regular"
        );
        document.documentElement.style.setProperty("--font-Bold", "Cairo-Bold");
        document.documentElement.style.setProperty(
          "--font-Light",
          "Cairo-Light"
        );
      }
    } else {
      i18n.changeLanguage(LanguagesEnum.EN);
      document.documentElement.style.setProperty(
        "--font-Regular",
        "IBMPlexSans-Regular"
      );
      document.documentElement.style.setProperty(
        "--font-Bold",
        "IBMPlexSans-Bold"
      );
      document.documentElement.style.setProperty(
        "--font-Light",
        "IBMPlexSans-Light"
      );
    }
  }, [i18n]);

  return (
    <Suspense fallback={<ComponentLoader />}>
      <main
        className="bg-white min-h-screen w-screen font"
        dir={i18n.language === LanguagesEnum.AR ? "rtl" : "ltr"}
      >
        <AppRouting />
      </main>
    </Suspense>
  );
}

export default App;
