import React, { Suspense } from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { appRoutesObj } from "./app.paths";
import ComponentLoader from "./components/component-loader/component-loader";
import { lazyWithRetry } from "./core/utils/lazy-loading.utils";

const BookingPage = lazyWithRetry(
  () => import("./pages/booking-page/booking-page")
);

const CongratsPage = lazyWithRetry(
  () => import("./pages/congrats-page/congrats-page")
);

const withSuspense = (WrappedComponent: React.ComponentType) => {
  return (
    <Suspense fallback={<ComponentLoader />}>
      <WrappedComponent />
    </Suspense>
  );
};

export const withSuspenseComponents = (element: JSX.Element) => {
  const newComponent = () => withSuspense(element.props.component);

  return { ...element, props: { ...element.props, component: newComponent } };
};

export function AppRouting() {
  return (
    <Suspense
      fallback={
        <div className="text-primary-200 pt-10">
          <ComponentLoader />
        </div>
      }
    >
      <Routes>
        <Route
          key="booking"
          path={appRoutesObj.getBaseUrl()}
          element={withSuspenseComponents(<BookingPage />)}
        />
        <Route
          key="congrats"
          path={appRoutesObj.getCongratsPath()}
          element={withSuspenseComponents(<CongratsPage />)}
        />

        <Route
          key="notDefined"
          path="*"
          element={<Navigate to={appRoutesObj.getBasePath()} />}
        />
      </Routes>
    </Suspense>
  );
}
